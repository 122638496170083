<template>
  <v-dialog
    @click:outside="back"
    @keydown.esc="back"
    persistent
    fullscreen
    v-model="show"
  >
    <div style="background-color: rgba(0, 0, 0, 0.7)" @click="back()">
      <GalleryImage :gallery="gallery" :image="image" :lazySrc="lazySrc" />
    </div>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import GalleryImage from "./components/GalleryImage.vue";
export default defineComponent({
  components: { GalleryImage },
  data() {
    return { show: true };
  },
  props: ["gallery", "image", "lazySrc"],
  methods: {
    back() {
      this.$router.push({ name: "Gallery", gallery: this.gallery });
    },
  },
});
</script>
